<template>
    <van-steps :active="active" active-icon="more" active-color="#38f" finish-icon="checked" class="steps">
        <van-step>面象采集</van-step>
        <van-step>舌象采集</van-step>
        <van-step>声音采集</van-step>
        <van-step>问卷调查</van-step>
    </van-steps>
</template>

<script>
export default {
    props: {
        active: {
            type: Number,
        },
    },
};
</script>

<style scoped>
.steps {
    /* flex-shrink: 0; */
    position: fixed;
    width: calc(100% - 20px);
    top: 0;
    opacity: 0.93;
}
</style>
